<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Linked Search</h1>
      <div class="btns-container">
        <VasionButton
          v-if="canConfigureSearches"
          id="btnNewLinkSearch"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="newLinkedSearch"
        >
          New Linked Search
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows.Values"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
    </div>
    <md-dialog id="deleteModal" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog
        :message="deleteMessage"
        @noButtonClick="showDeleteDialog = false"
        @yesButtonClick="deleteLinkedSearch()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'LinkSearch',
  components: {
    Loading,
  },
  data: function () {
    return {
      ColumnValues: [
        'SearchID',
        'Name',
        '_VasionEllipsisButton_',
      ],
      deleteLinkedSearchId: 0,
      deleteMessage: '',
      ellipsisButtonConfig: {
        buttons: [
          {
            id: 'edit-link-search-button',
            name: 'Edit Linked Search',
            icon: 'VasionEditIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
          {
            id: 'delete-link-search-button',
            name: 'Delete Linked Search',
            icon: 'VasionDeleteIcon',
            permissionGetter: 'common/canAccessAdmin',
          },
        ],
      },
      hiddenColumns: [
        'SearchID',
      ],
      isLoading: false,
      linkedSearchName: '',
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showDeleteDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    canConfigureSearches() { return this.$store.getters['common/canConfigureSearches'] },
  },
  created() {
    this.refreshTable()
  },
  methods: {
    async deleteLinkedSearch() {
      const response = await this.$store.dispatch('search/deleteLinkedSearch', this.deleteLinkedSearchId)
      const deleteSuccess = response.Value === 'True'
      this.showDeleteDialog = false

      if (deleteSuccess) {
        this.snackbarTitle = 'Delete Successful'
        this.snackbarSubTitle = `Deleted: "${this.linkedSearchName}"`
        await this.refreshTable()
      } else {
        this.snackbarTitle = 'Delete Failed'
        this.snackbarSubTitle = `Error Deleting: "${this.linkedSearchName}"\nPlease contact your Administrator if the problem persists.`
      }
      this.snackbarImage = deleteSuccess
      this.showSnackbar = true
    },
    ellipsisButtonClicked(payload) {
      const [id, name] = payload.item.Values
      this.linkedSearchName = name
      switch (payload.buttonId) {
        case 'edit-link-search-button':
          this.$router.push({ name: 'NewEditLinkedSearch', params: { linkedSearchId: id } })
          break;
        case 'delete-link-search-button':
          this.deleteMessage = `Are you sure you want to delete "${name}"?`
          this.deleteLinkedSearchId = id
          this.showDeleteDialog = true
          break;
        default:
          break;
      }
    },
    newLinkedSearch() { this.$router.push({ name: 'NewEditLinkedSearch', params: { linkedSearchId: 0 } }) },
    async refreshTable() {
      this.isLoading = true
      const searchList = await this.$store.dispatch('search/loadLinkedSearchList')

      this.tableData = {
        Rows: {
          Values: [],
        },
      }
      this.tableData.Rows.Values = searchList.map((searchElement) => {
        const dataRow = [
          searchElement.iID,
          searchElement.sName,
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.$router.push({ name: 'NewEditLinkedSearch', params: { linkedSearchId: data.Values[0] } })
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
